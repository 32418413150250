/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//  swiper
@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";

/* 기본 리셋 reset*/
*,
html,
body {
  // font-size: 16px; // rem 단위 폰트 지정
  color: unset;
  // font-weight: normal;
  // line-height: unset;
  letter-spacing: unset;
}

/* You can add global styles to this file, and also import other style files */
/*reset*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
address,
em,
img,
ins,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
audio,
video {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
main,
footer,
header,
hgroup,
menu,
nav,
section {
  box-sizing: border-box;
  display: block;
}
ul,
li,
dl,
dd,
dt {
  box-sizing: border-box;
  list-style: none;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  box-sizing: border-box;
}
input,
select {
  vertical-align: middle;
}

img {
  border-style: none;
  display: block;
}
address {
  font-style: normal;
}
button,
input,
textarea,
select,
a {
  border: 0;
  outline: none;
  resize: none;
  box-sizing: border-box;
}

input,
select,
button {
  vertical-align: middle;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}
/** Correct the inability to style clickable types in iOS and Safari. */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/** * Remove the inner border and padding in Firefox. */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/** * Restore the focus styles unset by the previous rule. */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/** * 1. Add the correct box sizing in IE 10. * 2. Remove the padding in IE 10. */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}
/** * Correct the cursor style of increment and decrement buttons in Chrome. */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
/** * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari. */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/** * Remove the inner padding in Chrome and Safari on macOS. */

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/** * 1. Correct the inability to style clickable types in iOS and Safari.
* 2. Change font properties to `inherit` in Safari. */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

button {
  cursor: pointer;
}

/*reset */

/********** ionic reset ********/
// ion-header,
// ion-footer {
//   --min-height: 56px;
//   --background: #fff;
// }
ion-fab-button {
  --background: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
}

ion-toolbar {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --min-height: 56px;
  --background: #fff;
}

ion-tab-bar {
  min-height: 66px;
  --background: #fff;
  --border: 0;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0 0 0;
}

.footer-ios ion-toolbar:first-of-type {
  --border-width: 0;
}

ion-label {
  margin: 0;
}

ion-list-header {
  padding: 0;
  min-height: auto;
  align-items: center;
}

ion-card {
  margin: 0;
  border-radius: 0;
  box-shadow: none;
}

ion-card-header {
  padding: 0;
}
ion-card-subtitle {
  margin: 0;
}
ion-card-title {
  font-weight: normal;
}

ion-card-content.card-content-ios {
  padding: 0;
}

ion-segment {
  --background: transparent;
  border-radius: 0;
}

ion-segment-button {
  margin: 0;
  --border-radius: 0;
  --border-width: 0;
  --indicator-box-shadow: none;
  min-height: unset;
}

ion-item {
  --border-width: 0;
  --inner-border-width: 0;
  --min-height: unset;
  --padding-start: 0;
  --inner-padding-end: 0;
  --background: transparent;
  --background-activated: none;
  --background-focused: none;
  --background-hover: none;
  --background-activated-opacity: 0;
  --background-focused-opacity: 0;
  --background-hover-opacity: 0;
  &::part(native) {
    padding: 0;
  }
}

ion-badge {
  padding: 0;
}

ion-button {
  margin: 0;
  height: auto;
  --padding-start: 0;
  --padding-end: 0;
  --border-radius: 0;
  --background: transparent;
  --background-activated: none;
  --background-focused: none;
  --background-hover: none;
  &[disabled] {
    opacity: 1;
  }
}

ion-item-divider {
  --background: unset;
  min-height: auto;
  --inner-padding-end: 0;
  --padding-start: 0;
}

ion-grid {
  padding: 0;
}

ion-col {
  padding: 0;
}

ion-accordion {
  ion-item {
    --background-activated: #fff;
  }
}

ion-radio {
  margin: 0;
}

ion-checkbox {
  margin: 0;
}

ion-select {
  --padding-top: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --placeholder-opacity: 1;
}

ion-avatar {
  --border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sc-ion-input-ios-h,
.sc-ion-textarea-ios-h {
  --padding-top: 0.625rem;
  --padding-end: 0.375rem;
  --padding-bottom: 0.625rem;
  --padding-start: 0.375rem;
  --placeholder-opacity: 1;
  --placeholder-color: #7e7e7d;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ffffff;
  caret-color: var(--ion-color-main);
}
ion-textarea.sc-ion-textarea-ios-h {
  --padding-start: 0.75rem !important;
  --padding-top: 0.75rem;
  --padding-end: 0.75rem;
  --padding-bottom: 0.75rem;
  --placeholder-color: #777777;
  border: 1px solid #2f2f2f;
  border-radius: 6px;
  word-break: break-all;
  white-space: pre-line;
  overflow-y: scroll;
}

.sc-ion-searchbar-ios-h {
  padding: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

/********** ionic reset ********/

////////그외 코드 참고사항

//######### modal css #########
//가로 세로 100%
.modal-wrapper.sc-ion-modal-ios {
  width: 100%;
  height: 100vh;
  border-radius: 0;
  --backdrop-opacity: 0.8;
  ion-backdrop {
    background-color: #161616;
    --backdrop-opacity: 0.8;
  }
}

//######### alert css #########
ion-alert.sc-ion-alert-ios-h {
  --max-width: 280px;
  --width: 100%;
  --backdrop-opacity: 0.8;
}

//말줄임 1줄
.row-ellipsis {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: pre-line;
}
//말줄인 2줄
.rows-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: pre-line;
}

.list-none {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 18.75rem;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  color: #777777;
  text-align: center;
}

.back {
  min-width: 44px;
  width: 44px;
  height: 44px;
  // background: url(./assets/icon/favicon.png) no-repeat; //url
  background-size: 100%;
  background-position: center;
}

.done {
  min-width: 44px;
  width: 44px;
  height: 44px;
  background: url(./assets/imgs/done.png) no-repeat; //url
  background-size: 100%;
  background-position: center;
}

// 프로필 설정 후 관리자 승인 대기중인 경우
.approval-wait {
  width: 100%;
  padding: 3.6875rem 1rem 1rem;
  .wait {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20.75rem;
    background: #242424;
    border: 1px solid #242424;
    border-radius: 4px;
    img {
      width: 2.8125rem;
      height: 3.8125rem;
      margin-bottom: 2.5rem;
    }
    p {
      font-size: 0.9375rem;
      line-height: 1.4375rem;
      font-weight: 300;
      color: #d1d0cf;
    }
  }
}

// **************************** custom **********************************************************************************

* {
  font-weight: 400;
  color: #ffffff;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

.mb {
  display: none;
}

ion-header {
  display: none;
  background: #161616;
  ion-toolbar {
    --background: #161616;
  }
  &.modal {
    ion-toolbar {
      --min-height: 65px;
      // --min-height: 26px;
      --background: #242424;
    }
  }
  &.pc {
    display: flex;
  }
}
.header-ios ion-toolbar:last-of-type {
  --border-width: 0 0 1px;
  --border-color: #2f2f2f;
}
.header-ios .no-border:last-of-type {
  --border-width: 0 0 0;
}

ion-footer {
  ion-toolbar {
    --padding-start: 1rem;
    --padding-end: 1rem;
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    --background: #161616;
  }
  &.modal {
    ion-toolbar {
      --background: #242424;
    }
  }
}

html.ios ion-modal.modal-card ion-header ion-toolbar:first-of-type,
html.ios ion-modal.modal-sheet ion-header ion-toolbar:first-of-type,
html.ios ion-modal ion-footer ion-toolbar:first-of-type {
  padding: 0;
}
html.ios ion-modal ion-header ion-toolbar {
  padding: 0;
  padding-top: var(--ion-safe-area-top, 0);
}
html.ios ion-modal ion-footer ion-toolbar {
  padding: 0;
  padding-bottom: var(--ion-safe-area-bottom, 0);
}

// toast 공통
ion-toast {
  --start: 1rem;
  --end: 1rem;
  --border-radius: 6px;
  font-family: "sCoreR";
  font-size: 0.8125rem;
  // line-height: 1.25rem;
  text-align: center;
  transform: translateY(-1.5625rem);
}

ion-item {
  &::part(detail-icon) {
    display: none;
  }
}

ion-radio {
  width: 0;
  height: 0;
  --color-checked: transparent;
}

ion-checkbox {
  width: 0;
  height: 0;
  overflow: hidden;
  --background: transparent;
  --border-color-checked: transparent;
  --background-checked: transparent;
  --checkmark-color: transparent;
  --checkmark-width: 0;
}

.sc-ion-label-ios-s p,
.card-content-ios p {
  margin: 0;
  color: #161616;
}

// 컨텐트 레이아웃
ion-content {
  --background: #161616;
  &.modal {
    --background: #242424;
  }
}
// 메인과 사이드메뉴를 나누기 위한 레이아웃
.global-container {
  display: flex;
  align-items: stretch;
  width: 100%;
  min-width: 100%;
  // min-height: 100vh;
}
// 우측 메인
.main-container {
  margin-left: 20%;
  // min-width: calc(1920px - 20%);
  min-width: 80%;
  .main-inner {
    margin: 0 auto;
    max-width: 768px;
    width: 100%;

    // 로그인
    &.account {
      padding: 12.125rem 1.875rem 6.125rem;
      .title {
        font-weight: 400;
        font-size: 1.75rem;
        line-height: 2.0625rem;
        color: #ffffff;
        border: 0;
      }
    }
    // 홈
    &.home {
      padding: 4.4375rem 1.875rem 1.5rem;
      background-color: #161616;
    }
    // 상세
    &.detail {
      padding: 5.9375rem 1.875rem 6.25rem;
      background-color: #161616;
      // .title {
      //   padding-bottom: 1.625rem;
      //   font-size: 3.125rem;
      //   line-height: 3.75rem;
      // }
    }
    // 공지사항/질문
    &.notifications,
    &.question {
      padding: 8.9375rem 1.875rem 7.3125rem;
      .title {
        padding: 1.0625rem 0;
        font-size: 1.75rem;
        line-height: 2.0625rem;
      }
    }
    // saved posts, only-me
    &.saved-posts,
    &.only-me {
      padding: 4.5rem 1.875rem;
      .title {
        padding: 1.75rem 0;
        border-bottom: 0;
      }
    }
    // profile
    &.profile {
      padding: 2.5625rem 1.875rem 6.125rem;
      .title {
        padding: 3.5rem 0;
      }
    }
    // 채팅
    &.chat {
      padding: 4.8125rem 1.875rem 0;
    }
    // 업로드
    &.upload-write {
      padding: 4.75rem 1.875rem 6.125rem;
      ion-input.basic-label {
        --placeholder-color: #777777;
      }
    }
    // 업로드
    &.upload {
      padding: 4.75rem 1.875rem 1.875rem;
    }
    // membership
    &.membership {
      padding: 3.8125rem 1.875rem;
      .title {
        padding: 2.25rem 0;
        border-bottom: 0;
      }
    }

    // 내정보
    &.mypage {
      padding: 5.25rem 1.875rem;
    }
  }
}

// 모달 공통
.modal-container {
  padding: 0 1rem 1rem;
  .sticky-header {
    position: sticky;
    top: 0;
    background: #242424;
    z-index: 100;
  }
  .title {
    padding: 0 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 2.5rem;
    text-align: center;
    border-bottom: 0;
  }
  .done-btn-wrap {
    justify-content: flex-end;
    padding: 0.625rem 1rem 0;
  }
}

.modal-default {
  // 기본 384사이즈
  // --width: 384px;
  // --height: 760px;
  --border-radius: 4px;
  --backdrop-opacity: 0.8;
  ion-backdrop {
    background-color: #161616;
  }
}
.video-wrap {
  display: flex !important;
  align-items: center !important;
}
// 맵
.map-modal {
  padding: 1.875rem;
  --width: 762px;
  --height: 762px;
}
// 메뉴
.menu-modal {
  --width: 384px;
  --height: 384px;
}
// 이미지,비디오 상세 모달
.image-detail-modal {
  padding: 1rem;
  --width: 708px;
  --height: 764px;
}
// popup
.cdk-overlay-dark-backdrop {
  background: rgba(22, 22, 22, 0.8);
  // background: #161616;
  // opacity: 0.8 !important;
}
.mat-dialog-container {
  padding: 0 !important;
  // max-width: 24rem;
  // width: 100%;
  // height: 23.75rem;
  border-radius: 2.91px !important;
  box-shadow: none;
  background: #303030;
}

// 홈 팝업
.home-ad-dialog {
  width: 100%;
  max-width: 384px !important;
}

// 가로 정렬 레이아웃
.d-flex {
  display: flex;
  align-items: center;
  width: 100%;
  &.jcsb {
    justify-content: space-between;
  }
  &.center {
    justify-content: center;
  }
}
// 세로 정렬 레이아웃
.d-col,
.d-col::part(native) {
  align-items: flex-start;
  flex-direction: column;
}

.val {
  position: absolute;
  padding-top: 4px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #ff0000;
}

.border-b {
  border-bottom: 1px solid #2f2f2f;
}

// 공통 라벨, 공통 인풋
.input-item.item-has-focus {
  .border-b {
    border-color: var(--ion-color-main);
  }
  ion-textarea {
    border-color: var(--ion-color-main);
  }
}
.basic-label,
.basic-label.sc-ion-label-ios-h {
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #f1f0f0;
  --color: #f1f0f0;
}

// 공통 버튼
.basic-btn {
  width: 100%;
  height: 3.125rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ffffff;
  --color: #ffffff;
  border-radius: 0.375rem;
  background: var(--ion-color-main);
  --color: #ffffff;
  --border-radius: 0.375rem;
  --background: var(--ion-color-main);
  &[disabled] {
    color: #777777;
    background: #41403f;
    --color: #777777;
    --background: #41403f;
  }
}
// border
.border-btn {
  height: 2.375rem;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #f1f0f0;
  --color: #f1f0f0;
  border: 1px solid var(--ion-color-main);
  border-radius: 0.3125rem;
  &[disabled] {
    color: #515050;
    --color: #515050;
    border-color: #515050;
  }
}

select {
  padding: 0 0.75rem 0 0.875rem;
  width: 100%;
  height: 2.75rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.125rem;
  color: #ffffff;
  background: #242424 url(./assets/imgs/icon-arrow-down.png) no-repeat 98% center / 0.9375rem 0.875rem;
  // color-scheme: dark;
  option[value=""][disabled] {
    display: none;
  }
}
// .other {
//   color: red;
// }

// 공통 아이템
.basic-item {
  margin-bottom: 1.75rem;
  // 멤버쉽인 경우
  &.membership {
    .image-wrap {
      &::after {
        display: block;
        content: "Membership";
        position: absolute;
        right: -1.4375rem;
        bottom: 1.1875rem;
        z-index: 100;
        min-width: 6.75rem;
        width: 6.75rem;
        height: 1.375rem;
        font-size: 0.8125rem;
        line-height: 1.125rem;
        color: #ffffff;
        text-align: center;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
        background: #3935cc;
        transform: rotate(315deg);
      }
    }
  }
  ion-card {
    width: 100%;
    background: transparent;
  }
  // 작성자 정보
  ion-card-title {
    padding: 0.8125rem 0;
    border-top: 1px solid #2f2f2f;
    border-bottom: 1px solid #2f2f2f;
    .left {
      gap: 0.625rem;
      width: auto;
    }
    ion-avatar {
      min-width: 2.3125rem;
      width: 2.3125rem;
      height: 2.3125rem;
      cursor: pointer;
    }
    .name {
      font-size: 0.9375rem;
      line-height: 1.0625rem;
      color: #ffffff;
    }
    .location {
      padding-top: 2px;
      font-weight: 400;
      font-size: 0.8125rem;
      line-height: 0.8125rem;
      color: #b4b1b0;
      cursor: pointer;
    }
    .more-btn {
      min-width: 1.5rem;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
    }
  }

  ion-card-content {
    flex-wrap: wrap;
    height: 22rem;
    // 게시글 이미지
    .image-wrap {
      position: relative;
      width: 48%;
      height: 100%;
      overflow: hidden;
      .image {
        width: 100%;
        height: 100%;
      }
      .img-icon {
        position: absolute;
        right: 0.375rem;
        top: 0.375rem;
        z-index: 100;
        width: 1.75rem;
        height: 1.75rem;
        z-index: 1;
      }
      .swiper-slide {
        position: relative;
        &::after {
          display: block;
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.5));
          z-index: 1;
        }
      }
    }
    // 게시글 내용
    .content-inner {
      padding: 1.125rem 1rem 0;
      padding-right: 0;
      width: 52%;
      height: 100%;
      // height: 346px;
      overflow-y: auto;
      overflow-x: hidden;
      .inner-top {
        position: relative;
      }
      // 상단 트레일러, 스크리너
      .badge-wrap {
        gap: 0.75rem;
        padding-bottom: 1.125rem;
        .type-badge {
          padding: 0.25rem;
          padding-left: 0.875rem;
          min-width: 5.5rem;
          width: auto;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.0625rem;
          color: #f1f0f0;
          border-radius: 17.5px;
          --background: #2f2f2f;
          cursor: pointer;
          .icon {
            margin-left: 0.75rem;
            min-width: 1.625rem;
            width: 1.625rem;
            height: 1.625rem;
          }
        }
      }
      // 게시글 상세 공통 타이틀
      .detail-title {
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.3125rem;
        color: #ffffff;
      }
      .detail-sub-title {
        padding-bottom: 0.75rem;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.25rem;
        color: #ffffff;
      }
      .detail-text {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #d1d0cf;
        word-break: break-all;
        white-space: pre-line;
      }
      //  게시글 상세 공통 정보
      .detail-info-wrap {
        padding: 0.625rem 0;
        p {
          display: flex;
          align-items: center;
          gap: 6px;
          padding: 4px 0;
          font-weight: 400;
          font-size: 0.9375rem;
          line-height: 1.1875rem;
          color: #e4e4e4;
          span {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 0.9375rem;
            line-height: 1.1875rem;
            color: #e4e4e4;
          }
          &.info {
            &::before {
              display: block;
              content: "";
              width: 4px;
              height: 4px;
              background: #515050;
            }
          }
        }
        .info.flex-layout {
          span {
            &::after {
              display: block;
              content: "";
              margin-left: 6px;
              width: 1px;
              height: 10px;
              background: #41403f;
            }
            &:last-child::after {
              display: none;
            }
          }
        }
      }
      .detail-wrap {
        padding: 1.5rem 0 1.875rem;
      }
      // 피드내용
      .feed-text {
        -webkit-line-clamp: 7;
        margin: 0 0 1.5rem;
        // min-height: 7.875rem;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: #f1f0f0;
        white-space: pre-line;
        word-break: keep-all;
      }

      // 배우
      .actor-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.625rem;
        margin-bottom: 0.75rem;
        max-width: 100%;
        span {
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: #b4b1b0;
        }
      }
      // 태그
      .tag-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;
        padding-bottom: 0.625rem;
        span {
          padding: 2px 6px;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: #ff782e;
          border-radius: 2px;
          background: #2f2f2f;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  // 게시글 정보
  .inner-botm {
    margin-top: 0.75rem;
    padding: 0.5rem;
    padding-left: 0.75rem;
    background: #1e1e1e;
    .left {
      gap: 0.75rem;
      width: auto;
      .like-icon,
      .comment-icon {
        margin-right: 4px;
        min-width: 0.9375rem;
        width: 0.9375rem;
        height: 0.875rem;
      }
      .num {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: #e4e4e4;
      }
    }
    .right {
      gap: 1rem;
      width: auto;
      ion-button {
        min-width: 1.5rem;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}
// 공통 댓글
.comment-wrap {
  // padding-bottom: 15.625rem;
  .comment-list {
    padding: 0.1875rem 0 0.75rem;
    border-top: 1px solid #2f2f2f;
    border-bottom: 1px solid #2f2f2f;
  }

  // 댓글
  .comment-item {
    padding: 0.875rem 0;
    .name {
      gap: 3.66px;
      margin-bottom: 2px;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
    .comment-icon {
      display: block;
      min-width: 0.75rem;
      width: 0.75rem;
      height: 0.8125rem;
      background: url(./assets/imgs/icon-reply.png) no-repeat center / 100% 100%;
    }
    .comment {
      font-weight: 400;
      font-size: 0.9375rem;
      line-height: 1.25rem;
      color: #e4e4e4;
      word-break: keep-all;
      white-space: pre-line;
    }
    .more-btn {
      align-self: flex-start;
      min-width: 1.5rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
// 댓글입력창
.comment-input-item {
  position: relative;

  .name-wrap {
    gap: 0.5rem;
    padding-bottom: 0.625rem;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  ion-avatar {
    min-width: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
  .d-flex.border {
    padding: 0.75rem;
    border: 1px solid #41403f;
    border-radius: 5px;
  }
  ion-item {
    width: 100%;
    height: 8.625rem;
    overflow-y: scroll;
    ion-textarea.sc-ion-textarea-ios-h {
      --padding-start: 0 !important;
      --padding-top: 0;
      --padding-end: 0;
      --padding-bottom: 0;
      max-height: 8.625rem;
      font-size: 0.9375rem;
      line-height: 1.1875rem;
      border: none;
      border-radius: 0;
      overflow-y: visible;
    }
    &.item-has-value {
      & + .send-btn {
        background-image: url(./assets/imgs/icon-send-input-on.png);
      }
    }
  }
  .send-btn {
    align-self: flex-end;
    margin-left: 1rem;
    min-width: 2rem;
    width: 2rem;
    height: 2.0625rem;
    border-radius: 5px;
    background: url(./assets/imgs/icon-send-input.png) no-repeat center / 100% 100%;
  }
}

// 테이블 공통
.table-wrap {
  padding-bottom: 3.4375rem;
  // min-height: 671px;
  table {
    width: 100%;
  }
  thead {
    th {
      margin-bottom: 0.5rem;
      padding: 0.75rem 0;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      color: #b4b1b0;
      text-align: center;
    }
  }

  tbody {
    tr {
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        background: rgba(0, 0, 0, 0.3);
      }
      &:first-child {
        .cont {
          margin-top: 1.8125rem;
        }
      }
    }
    td {
      // margin: 20px 0.9375rem;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      color: #b4b1b0;
      // word-break: keep-all;
      text-align: center;
      &:not(.cont) {
        padding: 1.375rem 0.4688rem;
        white-space: nowrap;
      }
      &.badge {
        padding-top: 0;
        padding-bottom: 0;
        vertical-align: middle;
      }
    }
    .cont {
      margin: 1.25rem 0.4688rem;
      // max-width: 20rem;
      max-width: 92%;
      text-align: left;
      color: #ffffff;
    }
  }

  &.mb {
    padding: 0.875rem 0;
  }
  // 모바일
  .table-item {
    padding: 0.875rem 0;
    .date {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: #b4b1b0;
    }
    .cont {
      margin: 0.375rem 0 0.5rem;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
    .name-wrap {
      gap: 0.75rem;
    }
    .name,
    .preview {
      width: auto;
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: #b4b1b0;
    }
    .admin-icon {
      margin-right: 0.25rem;
      width: 0.8125rem;
      height: 0.875rem;
      background: url(./assets/imgs/icon-admin.png) no-repeat center / 100% 100%;
    }
    .preview-icon {
      margin-right: 0.25rem;
      width: 1.125rem;
      height: 0.75rem;
      background: url(./assets/imgs/icon-preview.png) no-repeat center / 100% 100%;
    }
  }
}

// pagination
.pagination-wrap {
  padding: 0.75rem 0 1.0625rem;
  ngb-pagination {
    .pagination {
      display: flex;
      align-items: center;
      .page-item {
        &:nth-of-type(1) {
          width: 1.125rem;
          height: 1.3125rem;
          background: no-repeat center / 1.125rem 0.75rem;
          background-image: url(./assets/imgs/icon-page-first-step.png);
          span {
            display: none;
          }
        }
        &:nth-of-type(2) {
          width: 1.125rem;
          height: 1.3125rem;
          background: no-repeat center / 1.125rem 0.75rem;
          background-image: url(./assets/imgs/icon-page-prev-step.png);
          span {
            display: none;
          }
        }
        &:nth-last-of-type(1) {
          width: 1.125rem;
          height: 1.3125rem;
          background: no-repeat center / 1.125rem 0.75rem;
          background-image: url(./assets/imgs/icon-page-last-step.png);
          span {
            display: none;
          }
        }
        &:nth-last-of-type(2) {
          width: 1.125rem;
          height: 1.3125rem;
          background: no-repeat center / 1.125rem 0.75rem;
          background-image: url(./assets/imgs/icon-page-next-step.png);
          span {
            display: none;
          }
        }
        &.active {
          .page-link {
            font-weight: 700;
            color: #ffffff;
          }
        }
        .page-link {
          padding: 0 0.5625rem;
          font-weight: 400;
          font-size: 0.9375rem;
          line-height: 1.3125rem;
          color: #777777;
          background: transparent;
        }
        .sr-only {
          display: none !important;
        }
      }
    }
  }
  button:not(.num) {
    padding: 0 0.75rem;
    width: 1.125rem;
    height: 1.3125rem;
    background: no-repeat center / 1.125rem 0.75rem;
    &.first-step {
      background-image: url(./assets/imgs/icon-page-first-step.png);
    }
    &.prev-step {
      background-image: url(./assets/imgs/icon-page-prev-step.png);
    }
    &.next-step {
      background-image: url(./assets/imgs/icon-page-next-step.png);
    }
    &.last-step {
      background-image: url(./assets/imgs/icon-page-last-step.png);
    }
  }
  .num {
    padding: 0 0.5625rem;
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.3125rem;
    color: #777777;
    background: transparent;
    &.active {
      font-weight: 700;
      color: #ffffff;
    }
  }
  button {
    border-radius: 50%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.4) !important;
    }
  }
}

// segment
.seg-wrap {
  .seg-title-wrap {
    position: sticky;
    top: 0;
    z-index: 999;
    margin-bottom: 4px;
    border-bottom: 2px solid #41403f;
    background: #161616;
  }
  ion-segment {
    margin: 0 auto;
    max-width: 375px;
    ion-segment-button {
      min-height: 2.875rem;
      border-bottom: 2px solid transparent;
      --indicator-color: transparent;

      ion-label {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.25rem;
        color: #777777;
      }
      &.segment-button-checked {
        border-bottom: 2px solid var(--ion-color-main);
        ion-label {
          color: #ffffff;
        }
      }
    }
  }
}

.seg-wrap-foll {
  .seg-title-wrap {
    position: sticky;
    top: 0;
    z-index: 999;
    margin-bottom: 4px;
    border-bottom: 2px solid #41403f;
    background: #161616;
  }
  ion-segment {
    margin: 0 auto;
    min-width: 50%;
    ion-segment-button {
      min-height: 2.875rem;
      border-bottom: 2px solid transparent;
      --indicator-color: transparent;

      ion-label {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.25rem;
        color: #777777;
      }
      &.segment-button-checked {
        border-bottom: 2px solid var(--ion-color-main);
        ion-label {
          color: #ffffff;
        }
      }
    }
  }
}
// 공통 세그먼트
.img-seg-inner {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  .seg-item {
    position: relative;
    width: calc(33.3333% - 2.7px);
    height: 0;
    padding-bottom: calc(33.3333% - 2.7px);
    cursor: pointer;
    img {
      position: absolute;
    }
  }
  .img-icon {
    position: absolute;
    right: 0.375rem;
    top: 0.375rem;
    z-index: 100;
    width: 1.75rem;
    height: 1.75rem;
  }
}

//  searchbar
.search-wrap {
  ion-searchbar {
    height: 44px; // searchbar height
    border: 1px solid #41403f;
    border-radius: 5px;
    --border-radius: 5px;
    --background: transparent url(./assets/imgs/icon-search-line.png) no-repeat 10px center / 32px 24px;
    // 배경색 있는 searchbar, 돋보기 아이콘이 다를 때 작성하였습니다.
  }
  .searchbar-input-container.sc-ion-searchbar-ios {
    height: 44px; // 위에 searchbar height랑 동일하게 넣으시면 됩니다.
  }
  .searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
    padding-inline-start: 3.1875rem; // searchbar 왼쪽 여백 (돋보기 아이콘 디자인 간격 보면 됩니다.)
    padding-inline-end: 1rem; // searchbar 오른쪽 여백 (clear 버튼 디자인 간격 보면 됩니다.)
    // (여기에) font-style 넣어야될 것 같습니다...
    font-size: 1rem;
    line-height: 1.25rem;
    caret-color: var(--ion-color-main);
  }

  .searchbar-clear-button.sc-ion-searchbar-ios {
    width: 1.5rem;
    height: 1.5rem;
    color: #bcbcbc;
    background: url(./assets/imgs/search-reset.png) no-repeat center / 100%;

    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  // 아이콘 숨기기 (디자인과 달라서 지웠습니다.)
  .searchbar-search-icon,
  .searchbar-clear-icon {
    display: none;
    width: 0;
    height: 0;
  }
}

// checkbox, radio
.check-item {
  ion-checkbox {
    min-width: 1.25rem;
    height: 1.25rem;
    --border-color: #515050;
    --border-color-checked: #41403f;
    --background-checked: #41403f;
    --checkmark-width: 1.5px;
    --checkmark-color: var(--ion-color-main);
  }
  ion-label.sc-ion-label-ios-h {
    margin-bottom: 0;
    padding: 0.5625rem 0.625rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #777777;
  }
  ion-radio {
    min-width: 1.25rem;
    height: 1.25rem;
    border: 1px solid #515050;
    border-radius: 50%;
  }
  &.item-radio-checked,
  .item-checkbox-checked {
    ion-label.sc-ion-label-ios-h {
      color: #ffffff;
    }
    ion-radio {
      position: relative;
      border-color: #41403f;
      background: #41403f;
      &::after {
        display: block;
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 0.625rem;
        height: 0.5rem;
        background: url(./assets/imgs/icon-radio-check.png) no-repeat center / 100% 100%;
      }
    }
  }

  //
  &.all-check {
    background: #242424;
    ion-checkbox {
      --border-color-checked: var(--ion-color-main);
      --background-checked: var(--ion-color-main);
      --checkmark-color: #161616;
    }
    ion-radio {
      min-width: 1.25rem;
      height: 1.25rem;
      border: 1px solid #515050;
      border-radius: 50%;
    }
    ion-label {
      padding: 0.875rem 0.75rem 0.8125rem;
      font-size: 1rem;
      line-height: 1.25rem;
      color: #b4b1b0;
      white-space: pre-line;
      word-break: keep-all;
    }
  }
}

// 더보기 메뉴 공통
.mat-menu-panel {
  min-width: 139px !important;
  max-width: 139px !important;
  border-radius: 5px;
  box-shadow: none;
  background: #242424;
  .mat-menu-content:not(:empty) {
    padding-top: 0.4375rem;
    padding-bottom: 0.4375rem;
  }
  .mat-menu-item {
    padding: 0.4375rem 0.75rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #e4e4e4;
  }
}

// mypage, user-profile
.mat-wrap {
  mat-accordion.mat-accordion {
    width: 100%;
    .mat-expansion-panel {
      width: 100%;
      background: #242424;

      &:first-of-type,
      &:last-of-type {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:not([class*="mat-elevation-z"]) {
        margin: 0;
        box-shadow: none;
      }

      // 열렸을 때
      &.mat-expanded {
        .arrow-icon {
          transform: rotate(180deg);
        }
      }

      // 모바일에서 멋없게 뜨는걸 없애고 싶어서 호버 효과를 지웠습니다.
      &:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
        background: transparent;
      }
      // 제목 우측 화살표
      .mat-expansion-indicator::after {
        padding: 0;
        border: 0;
        transform: rotate(0);
        background: no-repeat center / 100% 100%;
        // 사이즈와 백그라운드로 화살표 이미지만 넣어서 사용하면 됩니다!
        // width: 24px;
        // height: 24px;
        // background-image: url();
      }

      // 헤더 높이 초기화, 헤더 css
      mat-expansion-panel-header {
        padding: 0 1rem;
        height: 2.8125rem; // 헤더 높이 css
        .mat-expansion-panel-header-title {
          gap: 0.5rem;
          .left {
            gap: 0.3125rem;
            width: auto;
            p {
              font-weight: 400;
              font-size: 0.875rem;
              line-height: 1.125rem;
              text-transform: uppercase;
              &.type-t {
                font-weight: 700;
              }
            }
          }
          .right {
            gap: 0.25rem;
            width: auto;
            p {
              font-weight: 400;
              font-size: 0.875rem;
              line-height: 1.125rem;
              color: #e4e4e4;
            }
            .line {
              width: 1px;
              height: 0.625rem;
              background: #41403f;
            }
          }
        }
      }
      .mat-expansion-panel-body {
        // 페널 내용 css
        padding: 0 1rem 1.25rem;
        .mat-text {
          padding-top: 0.625rem;
          border-top: 1px solid #2f2f2f;
          font-weight: 400;
          font-size: 0.9375rem;
          line-height: 1.1875rem;
          color: #d1d0cf;
          white-space: pre-line;
          word-break: break-all;
        }
      }
    }
  }
}

.arrow-icon {
  align-self: center;
  width: 0.9375rem;
  height: 0.875rem;
  background: url(./assets/imgs/icon-arrow-down.png) no-repeat center / 100% 100%;
  transition: all 0.2s;
}

// alert
.sc-ion-alert-ios-h {
  padding: 0 2.25rem;
  --background: #303030;
  .alert-wrapper {
    border-radius: 6px;
  }
  .alert-head {
    padding: 0;
    padding-bottom: 0.625rem;
    .alert-title {
      margin: 0;
      padding: 1.875rem 1rem 0;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.4375rem;
      color: #ffffff;
    }
  }
  .alert-message {
    padding: 1.1875rem 1.25rem 1.9375rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
    word-break: keep-all;
  }
  .alert-button-group {
    .alert-button {
      height: 3.125rem;
      border-color: #41403f;
      span {
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.875rem;
        color: #d1d0cf;
      }
      &.alert-button-role-ok {
        span {
          font-weight: 600;
          color: var(--ion-color-main);
        }
      }
      &.alert-button.ion-focused.sc-ion-alert-ios,
      .alert-tappable.ion-focused.sc-ion-alert-ios {
        background: rgba(22, 22, 22, 0.4);
      }
    }
  }
  .alert-input-group {
    padding: 0.25rem 1.25rem 2.5rem;
    input {
      margin: 0;
      padding: 0.9375rem 0.75rem;
      height: 3.125rem;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.25rem;
      color: #ffffff;
      border: 1px solid #515050;
      border-radius: 6px;
      background: transparent;
      caret-color: var(--ion-color-main);
    }
  }
}

ion-toast {
  font-size: 1.0625rem;
  color: #161616;
  --background: #f1f0f0;
}

// swiper
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-button-prev {
  display: none;
  width: 24px;
  height: 24px;
  background: url(./assets/imgs/icon-prev.png) no-repeat center / 18px 18px;
  &::after {
    display: none;
  }
}
.swiper-button-next {
  display: none;
  width: 24px;
  height: 24px;
  background: url(./assets/imgs/icon-next.png) no-repeat center / 18px 18px;
  &::after {
    display: none;
  }
}

.swiper-pagination-bullet {
  margin: 0 3px;
  width: 4px;
  height: 4px;
  box-shadow: none;
  background: #d8d8d8;
  opacity: 1;
  &.swiper-pagination-bullet-active {
    background: var(--ion-color-main);
  }
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 16px;
}

.no-profile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% + 9.375rem), -50%);
  .no-pf-infobox {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 28.25rem;
    background: #242424;
    border: 1px solid #242424;
    border-radius: 4px;
    padding: 1.5rem 1.25rem;
    img {
      width: 11.0625rem;
      height: 7.0625rem;
      margin-bottom: 2.875rem;
    }
  }
  .box-txt {
    font-size: 0.9375rem;
    line-height: 1.4375rem;
    font-weight: 300;
    color: #fff;
    text-align: center;
    padding-bottom: 3.75rem;

    span {
      font-weight: bold;
    }
  }
  ion-button {
    width: 100%;
    height: 3.125rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    --border-radius: 6px;
    border-radius: 6px;
    overflow: hidden;
  }
}

ion-modal.my-menu-modal {
  &::part(content) {
    background: transparent !important;
    --background: transparent;
  }
  .ion-page {
    display: flex;
    align-items: center;
    .modal {
      width: 24rem;
    }
  }
}

// ************************************ 반응형 ************************************

@media screen and (max-width: 1024px) and (min-width: 586px) {
  ion-header {
    display: none;
  }
  .no-profile {
    transform: translate(-50%, -50%);
  }
}

// 타블렛
@media screen and (max-width: 1024px) {
  // 헤더
  ion-header {
    display: block;
  }
  // 모바일
  .mb {
    display: flex;
    display: block;
  }
  // pc
  .pc {
    display: none;
  }
  .table-wrap.pc {
    padding-bottom: 2.6875rem;
  }
  .table-wrap.mb {
    display: none;
  }
  .title {
    border-bottom: 1px solid #2f2f2f;
  }

  .main-container {
    margin-left: 0;
    min-width: 100%;
    .main-inner {
      max-width: 100%;
      // 로그인
      &.account {
        padding: 5rem 1.875rem 6.4375rem;
      }
      // 홈
      &.home {
        padding: 0 1.875rem;
      }
      // 상세
      &.detail {
        padding: 1.875rem 1rem 3.75rem;
      }
      // 공지사항/질문믄
      &.notifications,
      &.question {
        padding: 2.6875rem 1.875rem 3.1875rem;
      }
      // 내정보
      &.mypage {
        padding: 0 1.875rem;
      }
      &.saved-posts,
      &.only-me {
        padding-top: 2.25rem;
        .title {
          padding: 1.5rem 0;
        }
      }
      // 업로드
      &.upload {
        padding: 1.875rem;
      }
      // 업로드
      &.upload-write {
        padding: 1.5rem 1.875rem 3.75rem;
      }
      // 채팅
      &.chat {
        padding: 1.1875rem 1.75rem 0;
        padding-bottom: var(--ion-safe-area-bottom, 0);
      }
      &.membership {
        padding: 1.75rem 1.875rem;
        .title {
          padding: 2rem 0;
        }
      }
    }
  }
  .no-profile {
    transform: translate(-50%, -50%);
    width: calc(100% - 2rem);
  }
}

// 모바일 (갤럭시-폴드 펼친 사이즈까지)
@media screen and (max-width: 585px) {
  html,
  body {
    font-size: 16px;
  }

  .table-wrap.pc {
    display: none;
  }
  .table-wrap.mb {
    display: block;
    padding-bottom: 2.25rem;
  }

  .swiper-button-prev {
    display: block;
  }
  .swiper-button-next {
    display: block;
  }

  .main-container {
    .main-inner {
      // 로그인
      &.account {
        padding: 3.125rem 1rem 2.5rem;
      }
      // 홈
      &.home {
        padding: 0 1rem;
      }
      // 상세
      &.detail {
        padding: 0 1rem 2.5rem;
      }
      // 공지사항/ 질문
      &.notifications,
      &.question {
        padding: 1.5rem 1rem 2.125rem;
        .title {
          font-size: 1.5rem;
        }
      }
      // 내정보
      &.mypage {
        padding: 0 1rem;
      }
      &.saved-posts,
      &.only-me {
        padding-top: 1.625rem;
        .title {
          padding: 1.5rem 0;
        }
      }
      &.membership {
        padding: 1.625rem 1rem;
        .title {
          padding: 1.5rem 0;
        }
      }
      // 채팅
      &.chat {
        padding: 1.1875rem 1rem 0;
        padding-bottom: var(--ion-safe-area-bottom, 0);
      }
      // 업로드
      &.upload {
        padding: 1rem;
      }
      // 업로드
      &.upload-write {
        padding: 1.625rem 1rem 2.5rem;
      }
    }
  }

  .basic-item {
    ion-card-content {
      height: auto;
      .image-wrap {
        width: 100%;
        // height: 18rem;
        .image,
        .swiper-slide {
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: 100%;
        }
        img {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      .content-inner {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        max-height: 100%;
        height: 100%;
        overflow: visible;
        .inner-top {
          height: 18rem;
          overflow-y: scroll;
          overflow-x: hidden;
          .feed-text {
            margin: 0 0 1.25rem;
            -webkit-line-clamp: 9;
          }
        }
      }
    }
  }

  // 모달 사이즈 조정
  ion-header.modal,
  ion-footer.modal {
    ion-toolbar {
      --background: #161616;
    }
  }

  ion-content.modal {
    --background: #161616;
  }
  .modal-container {
    .title {
      display: none;
    }
    .done-btn-wrap {
      display: none;
    }
  }
  .basic-modal,
  .modal-default {
    --width: 100%;
    --height: 100%;
  }
  .map-modal {
    --height: 344px;
  }
  .menu-modal {
    padding: 0 2.25rem;
    --height: 364px;
  }

  // 이미지,비디오 상세 모달
  .image-detail-modal {
    --width: 100%;
    --height: 344px;
  }

  // 세그먼트
  .img-seg-inner {
    .seg-item {
      width: calc(50% - 2px);
      padding-bottom: calc(50% - 2px);
    }
  }

  ion-modal.my-menu-modal {
    &::part(content) {
      background: transparent !important;
      --background: transparent;
    }
    .ion-page {
      display: flex;
      align-items: center;
      .modal {
        width: 100%;
      }
    }
    .modal-container {
      padding: 0 2.25rem;
      .title {
        display: none;
      }
      .done-btn-wrap {
        display: flex;
      }
    }
  }
}

// 마이페이지 메터리얼
@media screen and (max-width: 420px) {
  .mat-wrap {
    mat-accordion.mat-accordion {
      .mat-expansion-panel {
        mat-expansion-panel-header {
          height: 4.125rem;
          .mat-expansion-panel-header-title {
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
    }
  }
}

//벨리데이션
.err {
  color: #ff0000;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

//다음 주소 창

.popup-top {
  position: relative;
  height: 3.5rem;
  background-color: #2f2f2f;
  // border-bottom: 1px solid #eeeeee;

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
    line-height: 2.5rem;
    font-weight: 400;
    color: #fff;
  }

  ion-button {
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
    width: 2.75rem;
    height: 2.75rem;
    min-height: 2.75rem !important;
    --background: transparent;
    background: url(./assets/imgs/modal-close.png) center/2.75rem no-repeat;
  }
}

.pointer {
  cursor: pointer;
}

// 루트 사이즈 조정
@media screen and (max-width: 340px) {
  html,
  body {
    font-size: 15px;
  }
}
@media screen and (max-width: 300px) {
  *,
  html,
  body {
    font-size: 14px;
  }
}
